import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Mentee } from 'src/app/shared/types/mentee.types';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MenteeService {
  constructor(private http: HttpClient) {}

  public getAllMentees(params: HttpParams) {
    return this.http.get<Mentee[]>(environment.apiUrl + '/mentees/', { params: params });
  }

  /**
   * Returns submissions
   * @returns MenteesData
   */
  public getMenteeData(model_id: string) {
    return this.http.get<Mentee>(environment.apiUrl + `/mentees/${model_id}/`);
  }

  public updateMentee(menteeId: string, updatedMentee: Partial<Mentee>) {
    return this.http.patch<Mentee>(environment.apiUrl + `/mentees/${menteeId}/`, updatedMentee);
  }
}

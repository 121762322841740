import { Action } from '@ngrx/store';
import { Coach } from 'src/app/shared/types/coach.types';

export const COACHES_SET_INITIAL = '[COACHES] Set initial';
export const COACHES_LOAD = '[COACHES] Load coaches';
export const COACHES_LOAD_SUCCESS = '[COACHES] Load coaches successful';
export const COACHES_LOAD_ERROR = '[COACHES] Load coaches failed';
export const UPDATE_COACHES = '[COACHES] Update coaches';
export const UPDATE_COACHES_SUCCESS = '[COACHES] Update coaches successful';
export const UPDATE_COACHES_ERROR = '[COACHES] Update coaches failed';

export class CoachesLoad implements Action {
  readonly type = COACHES_LOAD;
  constructor(public forceUpdate: boolean = false) {}
}

export class CoachesLoadSuccess implements Action {
  readonly type = COACHES_LOAD_SUCCESS;
  constructor(public payload: Coach[]) {}
}

export class CoachesLoadError implements Action {
  readonly type = COACHES_LOAD_ERROR;
}

export type Actions = CoachesLoad | CoachesLoadSuccess | CoachesLoadError;

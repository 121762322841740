import { Action } from '@ngrx/store';
import { Mentor } from 'src/app/shared/types/mentor.types';

export const MENTORS_LOAD = '[MENTORS] Load mentors';
export const MENTORS_LOAD_SUCCESS = '[MENTORS] Load mentors successful';
export const MENTORS_LOAD_ERROR = '[MENTORS] Load mentors failed';
export const UPDATE_MENTORS = '[MENTORS] Update mentors';
export const UPDATE_MENTORS_SUCCESS = '[MENTORS] Update mentors successful';
export const UPDATE_MENTORS_ERROR = '[MENTORS] Update mentors failed';

export class MentorsLoad implements Action {
  readonly type = MENTORS_LOAD;
  constructor(public forceUpdate: boolean = false) {}
}

export class MentorsLoadSuccess implements Action {
  readonly type = MENTORS_LOAD_SUCCESS;
  constructor(public payload: Mentor[]) {}
}

export class MentorsLoadError implements Action {
  readonly type = MENTORS_LOAD_ERROR;
}

export class UpdateMentors implements Action {
  readonly type = UPDATE_MENTORS;
  constructor(public submissionId: string, public mentors: Mentor) {}
}

export class UpdateMentorsSuccess implements Action {
  readonly type = UPDATE_MENTORS_SUCCESS;
  constructor(public payload: Mentor[]) {}
}

export class UpdateMentorsError implements Action {
  readonly type = UPDATE_MENTORS_ERROR;
  constructor(public id: string) {}
}

export type Actions = MentorsLoad | MentorsLoadSuccess | MentorsLoadError | UpdateMentors | UpdateMentorsSuccess | UpdateMentorsError;

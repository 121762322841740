import { Action } from '@ngrx/store';
import { Mentee } from 'src/app/shared/types/mentee.types';

export const MENTEES_SET_INITIAL = '[MENTEES] Set initial';
export const MENTEES_LOAD = '[MENTEES] Load mentees';
export const MENTEES_LOAD_SUCCESS = '[MENTEES] Load mentees successful';
export const MENTEES_LOAD_ERROR = '[MENTEES] Load mentees failed';
export const UPDATE_MENTEES = '[MENTEES] Update mentees';
export const UPDATE_MENTEES_SUCCESS = '[MENTEES] Update mentees successful';
export const UPDATE_MENTEES_ERROR = '[MENTEES] Update mentees failed';

export class MenteesLoad implements Action {
  readonly type = MENTEES_LOAD;
  constructor(public forceUpdate: boolean = false) {}
}

export class MenteesLoadSuccess implements Action {
  readonly type = MENTEES_LOAD_SUCCESS;
  constructor(public payload: Mentee[]) {}
}

export class MenteesLoadError implements Action {
  readonly type = MENTEES_LOAD_ERROR;
}

export type Actions = MenteesLoad | MenteesLoadSuccess | MenteesLoadError;

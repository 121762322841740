import { Action } from '@ngrx/store';
import { Lead } from 'src/app/shared/types/lead.types';

export const LEADS_SET_INITIAL = '[LEADS] Set initial';
export const LEADS_LOAD = '[LEADS] Load leads';
export const LEADS_LOAD_SUCCESS = '[LEADS] Load leads successful';
export const LEADS_LOAD_ERROR = '[LEADS] Load leads failed';
export const UPDATE_LEADS = '[LEADS] Update leads';
export const UPDATE_LEADS_SUCCESS = '[LEADS] Update leads successful';
export const UPDATE_LEADS_ERROR = '[LEADS] Update leads failed';

export class LeadsLoad implements Action {
  readonly type = LEADS_LOAD;
  constructor(public forceUpdate: boolean = false) {}
}

export class LeadsLoadSuccess implements Action {
  readonly type = LEADS_LOAD_SUCCESS;
  constructor(public payload: Lead[]) {}
}

export class LeadsLoadError implements Action {
  readonly type = LEADS_LOAD_ERROR;
}

export type Actions = LeadsLoad | LeadsLoadSuccess | LeadsLoadError;
